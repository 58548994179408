@use '../_prefix.scss' as *;
@use '@ingka/price-module/_mixins.scss' as price-module;
@use '@ingka/commercial-message/_mixins.scss' as commercial-message;

@include price-module.price-module($prefix);
@include commercial-message.commercial-message($prefix);

/*
  Layout workaround
 
  For dual price currency, avoid some margin.
  This is being used by ui/list/summary/price-row-dual.tsx
 */
.remove-margin .list-ingka-price-module__current-price {
    margin-inline-end: 0;
}
.remove-margin .list-ingka-price-module__information {
    margin-bottom: 0;
}
