@use '../_prefix.scss' as *;
@use '@ingka/button/_mixins.scss' as button;
@use '@ingka/toast/_mixins.scss' as toast;

@include button.btn($prefix);
@include toast.toast($prefix);

// If you need rtl styles
@include toast.toast-rtl($prefix);

.list-ingka-toast {
    z-index: 100001;
}
